export default (dataAsString) => {
  const uint8 = new Uint8Array(dataAsString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dataAsString.length; i++) {
    let x = dataAsString.charCodeAt(i);
    if (x >= 1040 && x <= 1103) {
      // Символы А..Я а..я
      x -= 848;
    } else if (x === 1025) {
      // Символ Ё
      x = 168;
    } else if (x === 1105) {
      // Символ ё
      x = 184;
    }
    uint8[i] = x;
  }

  return uint8;
};
