export const FIELDS = {
  lang: {
    label: 'Язык',
  },
  department: {
    label: 'Департамент',
    userField: 'deptName',
  },
  organization: {
    label: 'Группирующий узел',
  },
  company: {
    label: 'Компания',
  },
  lastName: {
    label: 'Фамилия',
  },
  firstName: {
    label: 'Имя',
  },
  middleName: {
    label: 'Отчество',
  },
  position: {
    label: 'Должность',
    userField: 'appointmentName',
  },
  mobilePhone: {
    label: 'Номер мобильного телефона',
    userField: 'mobilePhone',
  },
  workPhone: {
    label: 'Номер рабочего телефона',
    userField: 'extPhone',
  },
  email: {
    label: 'Адрес электронной почты',
    userField: 'login',
  },
  address: {
    label: 'Адрес',
    userField: 'location',
  },
  type: {
    label: 'Тип визитки',
  },
  employeeType: {
    label: 'Вы сотрудник B2B\\B2C\\B2G',
  },
  paperBgType1: {
    label: 'Тип визитки',
  },
  paperBgType2: {
    label: 'Тип визитки',
  },
};
export const FIELD_IDS = {
  sm1: {
    lang: 'CFD0004408',
    department: 'CFD0005037',
    organization: 'CFD0004649',
    company: 'CFD0004389',
    lastName: 'CFD0004392',
    firstName: 'CFD0004393',
    middleName: 'CFD0004394',
    position: 'CFD0004391',
    mobilePhone: 'CFD0004399',
    workPhone: 'CFD0004400',
    email: 'CFD0004401',
    address: 'CFD0004396',
    type: process.env.VUE_APP_BUSINESS_CARD_TYPE_ID,
    employeeType: 'CFD0010756',
    paperBgType1: 'CFD0010759',
    paperBgType2: 'CFD0010760',
  },
  598: {
    lang: '15969',
    department: '15978',
    organization: '15970',
    company: '15979',
    lastName: '15984',
    firstName: '15985',
    middleName: '15986',
    position: '15980',
    mobilePhone: '15988',
    workPhone: '15989',
    email: '15990',
    address: '15981',
    type: '15973',
    employeeType: '27858',
    paperBgType1: '27860',
    paperBgType2: '27862',
  },
  77: {
    lang: '72788421',
    department: '72788435',
    organization: '72788422',
    company: '72788436',
    lastName: '72788441',
    firstName: '72788442',
    middleName: '72788443',
    position: '72788437',
    mobilePhone: '72788445',
    workPhone: '72788446',
    email: '72788447',
    address: '72788438',
    type: '72788430',
    employeeType: '72788423',
    paperBgType1: '72788425',
    paperBgType2: '72788427',
  },
};

export const LANGS = {
  RU: {
    text: 'Русский',
    code: 'Ru',
  },
  EN: {
    text: 'Английский',
    code: 'En',
  },
};

export const TYPES = {
  paper: {
    id: 'paper',
    name: 'Бумажная',
  },
  plastic: {
    id: 'plastic',
    name: 'Пластиковая с QR-кодом',
  },
};
export const BG_TYPES = {
  paper: {
    blue: {
      id: 'blue',
      name: 'Синяя',
      fileName: 'card-bg__blue',
    },
    space: {
      id: 'space',
      name: 'Космос',
      fileName: 'card-bg__space',
    },
    gray: {
      id: 'gray',
      name: 'Серая',
      fileName: 'card-bg__gray',
    },
    job: {
      id: 'job',
      name: 'Работа в Ростелекоме',
      fileName: 'card-bg__job',
    },
  },
};

export const COLOR_QR_CODE = '#7D95A9';
export const SITE_FOR_PLASTIC_CARD = 'http://b2b.rt.ru/';
