<script>
import { mapActions, mapState } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import unicodeToWin1251 from '@/utils/unicodeToWin1251';
import { LANGS, BG_TYPES } from '@/constants/businessCard';

export default {
  props: {
    model: {
      type: Object,
      default: undefined,
    },
    currentChecklist: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isDrawed: false,
      langs: LANGS,
      bgTypes: BG_TYPES,
      dataList: [],
      imgs: [],
    };
  },
  computed: {
    ...mapState('ticket', ['customServiceFiles']),
    dataListFile() {
      if (this.dataList) {
        return this.makeCSV(this.dataList);
      }

      return null;
    },
  },
  methods: {
    ...mapActions('ticket', ['setCustomServiceFiles']),

    fillMultiLineText(
      context,
      position,
      text,
      font,
      fontSize,
      marginBottom,
      lineHeight,
      maxWidth = 180,
    ) {
      const lines = [];
      let width = 0;
      let i;
      let j;
      let result;

      // Font and size is required for ctx.measureText()
      // eslint-disable-next-line no-param-reassign
      context.font = font;

      // Start calculation
      while (text && text.length) {
        // eslint-disable-next-line camelcase,no-plusplus
        for (
          i = text.length;
          context.measureText(text.substr(0, i)).width > maxWidth;
          // eslint-disable-next-line no-plusplus
          i--
        );

        result = text.substr(0, i);

        // ???
        if (i !== text.length) {
          for (
            j = 0;
            result.indexOf(' ', j) !== -1;
            j = result.indexOf(' ', j) + 1
          );
        }

        lines.push(result.substr(0, j || result.length));
        width = Math.max(
          width,
          context.measureText(lines[lines.length - 1]).width,
        );
        // eslint-disable-next-line no-param-reassign
        text = text.substr(lines[lines.length - 1].length, text.length);
      }

      // Render
      let textHeight = 0;
      Object.assign(textHeight, position.YPosition);
      // eslint-disable-next-line no-plusplus
      for (i = 0, j = lines.length; i < j; ++i) {
        textHeight = (fontSize + lineHeight) * i;
        context.fillText(
          lines[i],
          position.XPosition,
          position.YPosition + (fontSize + lineHeight) * i,
        );
      }
      // eslint-disable-next-line no-param-reassign
      position.YPosition += textHeight + marginBottom;
    },

    dataURItoBlob(dataURI) {
      const binary = atob(dataURI.split(',')[1]);
      const array = [];
      for (let i = 0; i < binary.length; i += 1) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    },
    async getBlobByUrl(dataURL, fileName) {
      // eslint-disable-next-line no-return-await
      const blob = await axios
        .get(dataURL, { responseType: 'blob' })
        .then((res) => res.data);

      let file = null;
      try {
        file = new File([blob], fileName, { type: 'image/jpeg' });
      } catch (e) {
        console.log("It's ie and this is normal. Or not... ", e);
        const newBlob = new Blob([blob], { type: 'image/jpeg' });
        newBlob.lastModifiedDate = new Date();
        newBlob.name = fileName;
        file = newBlob;
      }

      return file;
    },
    updateDataList() {
      const dataList = [];
      const pushToDataList = (field, groupId) => {
        const value = groupId && this.model[groupId] ? this.model[groupId][field.id] : this.model[field.id];
        if (!isEmpty(value)) {
          if (field.class === 'select') {
            const textValue = field.listValues.find((v) => v.id === value);
            if (!isEmpty(textValue)) {
              dataList.push([field.name, textValue.name]);
            }
          } else {
            dataList.push([field.name, value]);
          }
        }
      };
      this.currentChecklist.forEach((field) => {
        if (field.class === 'group') {
          field.childs.forEach((child) => pushToDataList(child, field.id));
        } else {
          pushToDataList(field);
        }
      });
      this.dataList = dataList;
    },
    makeCSV(arr) {
      let data = arr.map((i) => i.join(';')).join('\r\n');

      data = unicodeToWin1251(data);

      const file = new Blob([data], { type: 'text/csv;charset=utf-8' });
      file.lastModifiedDate = new Date();
      file.name = 'data.csv';
      file.isBlob = true;

      return file;
    },
  },
  watch: {
    model() {
      this.updateDataList();
    },
    dataListFile(val) {
      this.setCustomServiceFiles([val, ...this.imgs]);
    },
    imgs(val) {
      this.setCustomServiceFiles([this.dataListFile, ...val]);
    },
  },
  mounted() {
    this.updateDataList(this.model);
    // this.$eventBus.$on('update-model', this.updateDataList);
  },
  destroyed() {
    // this.$eventBus.$off('update-model', this.updateDataList);
  },
};
</script>
